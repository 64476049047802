import 'what-input';
import 'slick-carousel';
import 'slicknav/dist/jquery.slicknav.js';
import { Fancybox } from '@fancyapps/ui';
import { fr } from '@fancyapps/ui/dist/fancybox/l10n/fr.esm.js';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {
    searchBox, hits, index, configure, toggleRefinement, panel, pagination, refinementList, rangeInput
} from 'instantsearch.js/es/widgets';
import { connectRange } from 'instantsearch.js/es/connectors';


/* dateRange Input */

const renderDateRangeInput = (renderOptions, isFirstRender) => {
    const { start, range, refine, widgetParams } = renderOptions;
    const [min, max] = start;

    if (isFirstRender) {
        const form = document.createElement('form');

        form.addEventListener('submit', event => {
            event.preventDefault();
        });

        form.addEventListener('change', event => {
            const form = event.target.closest('form');

            const minTimestamp = form.elements.min.value ? Date.parse(form.elements.min.value)/1000 : undefined;
            const maxTimestamp = form.elements.max.value ? Date.parse(form.elements.max.value)/1000 : undefined;

            refine([
                minTimestamp,
                maxTimestamp,
            ]);
        });

        widgetParams.container.appendChild(form);

        return;
    }

    const minDate = Number.isFinite(min) ? new Date(min*1000) : null;
    const minDateString = Number.isFinite(min) ? (minDate.getUTCFullYear() + '-' + String(minDate.getUTCMonth()+1).padStart(2, '0') + '-' + String(minDate.getUTCDate()).padStart(2, '0')) : '';
    const maxDate = Number.isFinite(max) ? new Date(max*1000) : null;
    const maxDateString = Number.isFinite(max) ? (maxDate.getUTCFullYear() + '-' + String(maxDate.getUTCMonth()+1).padStart(2, '0') + '-' + String(maxDate.getUTCDate()).padStart(2, '0')) : '';

    widgetParams.container.querySelector('form').innerHTML = `
        <div class="grid-x align-middle">
            <div class="cell small-5">
                <input
                type="date"
                class="date-range-input"
                name="min"
                value="${minDateString}"
                />
            </div>
            <div class="cell small-2">
                <div class="text-center">
                    au
                </div>
            </div>
            <div class="cell small-5">
                <input
                type="date"
                name="max"
                class="date-range-input"
                value="${maxDateString}"
                />
            </div>
            <input type="submit" hidden/>
        </div>
    `;
};

// Create the custom widget
const dateRangeInput = connectRange(
    renderDateRangeInput
);

/* Global Search Config */
const algoliaClient = algoliasearch('UOJAAW3RI9', '7bb7870ea7dd568385cf5c199a1b233e');
const indexPrefix = 'production_' + '';
// const algoliaClient = algoliasearch('J06RXZSO46', '36340a8e6de4248ff6798664d62370fe');
// const indexPrefix = 'local_';

const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }

        return algoliaClient.search(requests);
    },
};

const fullSearchClient = {
    ...algoliaClient,
    search(requests) {
        return algoliaClient.search(requests);
    },
}


/* Header Search Config */

const headerSearch = instantsearch({
    indexName: indexPrefix + 'tv_shows',
    searchClient,
    onStateChange({ uiState, setUiState }) {
        const searchResults = $('.header .search-results');
        // console.log(uiState.production_tv_shows.query);
        if (uiState[indexPrefix + 'tv_shows'].query) {
            searchResults.css('display', 'flex');
        } else {
            searchResults.hide();
        }
        setUiState(uiState);
    },
});

headerSearch.addWidgets([
    searchBox({
        container: '.header #searchbox',
        placeholder: 'Rechercher',
    }),

    configure({
        hitsPerPage: 5,
    }),

    toggleRefinement({
        container: '.header #toggleqjq',
        attribute: 'show_on_qjq',
        on: true,
        off: true
    }),

    hits({
        container: '.header #hits-tv-shows',
        cssClasses: {
            item: 'search-result-item',
        },
        templates: {
            empty: 'Aucun résultat',
            item(hit, { html, components }) {
                return html`
                <a href="/emissions/${hit.slug}" class="search-result">
                    <div class="search-result-image">
                        <img src="${hit.image}" alt="" />
                    </div>
                    <div class="search-result-infos">
                        <div class="search-result-title">${components.Highlight({ attribute: 'title', hit })}</div>
                        <div class="search-result-type">${hit.tv_show_type}</div>
                        <div class="search-result-dates">${hit.start_date ? new Date(hit.start_date * 1000).getFullYear() : ''}${hit.end_date && new Date(hit.start_date * 1000).getFullYear() !== new Date(hit.end_date * 1000).getFullYear() ? `-${new Date(hit.end_date * 1000).getFullYear()}` : ''}</div>
                    </div>
                </a>
              `;
            },
        },
    }),

    index({ indexName: indexPrefix + 'persons' })
        .addWidgets([
            configure({
                hitsPerPage: 5,
            }),

            hits({
                container: '.header #hits-persons',
                cssClasses: {
                    item: 'search-result-item',
                },
                templates: {
                    empty: 'Aucun résultat',
                    item(hit, { html, components }) {
                        return html`
                        <a href="/personnes/${hit.slug}" class="search-result is-person">
                            <div class="search-result-image">
                                <img src="${hit.image}" alt="" />
                            </div>
                            <div class="search-result-infos">
                                <div class="search-result-title">${components.Highlight({ attribute: 'name', hit })}</div>
                                <div class="search-result-type">${hit.main_roles.join(', ')}</div>
                            </div>
                        </a>
                      `;
                    },
                },
            }),
        ]),

    index({ indexName: indexPrefix + 'tv_show_members' })
        .addWidgets([
            configure({
                hitsPerPage: 5,
            }),

            hits({
                container: '.header #hits-members',
                cssClasses: {
                    item: 'search-result-item',
                },
                templates: {
                    empty: 'Aucun résultat',
                    item(hit, { html, components }) {
                        return html`
                        <a href="/personnes/${hit.member_slug}" class="search-result is-person">
                            <div class="search-result-image">
                                <img src="${hit.image}" alt="" />
                            </div>
                            <div class="search-result-infos">
                                <div class="search-result-title">${components.Highlight({ attribute: 'character_name', hit })}</div>
                                <div class="search-result-character">${hit.member}</div>
                            </div>
                        </a>
                        `;
                    },
                },
            }),
        ]),
]);

headerSearch.start();


/* TV Shows Advanced Search */

if (document.querySelector('.advanced-tvshow-search')) {

    const advancedTvShowSearch = instantsearch({
        indexName: indexPrefix + 'tv_shows',
        searchClient: fullSearchClient,
    });

    advancedTvShowSearch.addWidgets([
        searchBox({
            container: '.advanced-search #searchbox',
            placeholder: 'Rechercher',
        }),

        configure({
            hitsPerPage: 5,
            attributesToRetrieve: [
                '*' // retrieves all attributes
            ]
        }),

        toggleRefinement({
            container: '.advanced-search #toggleqjq',
            attribute: 'show_on_qjq',
            on: true,
            off: true
        }),

        hits({
            container: '.advanced-search #hits',
            cssClasses: {
                item: 'item-image',
            },
            transformItems: (items) => {
                return items.map((item) => {
                    var div = document.createElement('div');
                    div.innerHTML = item.description;
                    item.description = div.innerText;
                    if (item.description.length > 300) {
                        item.description = item.description.substring(0,300) + "...";
                    }
                    return item;   
                });
            },
            templates: {
                empty: 'Aucun résultat',
                item(hit, { html, components }) {
                    return html`
                        <div>
                            <a href="/emissions/${hit.slug}">
                                <h3>${components.Highlight({ attribute: 'title', hit })}</h3>
                            </a>
                            <p>${hit.description}</p>
                            <a href="/emissions/${hit.slug}" class="button primary small">Consulter</a>
                        </div>
                        ${hit.image
                                ? html`
                                <a href="/emissions/${hit.slug}" class="thumbnail">
                                    <img src="${hit.image}" alt="" />
                                </a>
                            ` : ''}
                `;
                },
            },
        }),

        panel({
            templates: { header: 'Diffuseur' },
        })(refinementList)({
            container: '#broadcasters-list',
            attribute: 'broadcasters',
        }),

        panel({
            templates: { header: 'Genre' },
        })(refinementList)({
            container: '#genres-list',
            attribute: 'tv_show_type',
        }),

        panel({
            templates: { header: 'Date de début' },
        })(dateRangeInput)({
            container: '#start-date-range',
            attribute: 'start_date',
        }),

        panel({
            templates: { header: 'Date de fin' },
        })(dateRangeInput)({
            container: '#end-date-range',
            attribute: 'end_date',
        }),

        pagination({
            container: '#pagination',
        })
    ]);

    advancedTvShowSearch.start();
}

if (document.querySelector('.advanced-persons-search')) {

    const advancedTvShowSearch = instantsearch({
        indexName: indexPrefix + 'persons',
        searchClient: fullSearchClient,
    });

    advancedTvShowSearch.addWidgets([
        searchBox({
            container: '.advanced-search #searchbox',
            placeholder: 'Rechercher',
        }),

        configure({
            hitsPerPage: 5,
            attributesToRetrieve: [
                '*' // retrieves all attributes
            ]
        }),

        toggleRefinement({
            container: '.advanced-search #toggleqjq',
            attribute: 'show_on_qjq',
            on: true,
            off: true
        }),

        hits({
            container: '.advanced-search #hits',
            cssClasses: {
                item: 'item-person',
            },
            templates: {
                empty: 'Aucun résultat',
                item(hit, { html, components }) {
                    return html`
                    <a href="/personnes/${hit.slug}" class="grid-x grid-margin-x" style="width:100%;">
                        <div class="cell small-2">
                            ${hit.image
                                    ? html`
                                    <div class="thumbnail">
                                        <img src="${hit.image}" alt="" />
                                    </div>
                                ` : html`<div class="thumbnail is-empty"></div>`}
                        </div>
                        <div class="cell small-10">
                            <strong>${components.Highlight({ attribute: 'name', hit })}</strong>
                            <div class="search-result-type">${hit.main_roles.join(', ')}</div>
                        </div>
                    </a>
                `;
                },
            },
        }),

        panel({
            templates: { header: 'Rôles' },
        })(refinementList)({
            container: '#roles-list',
            attribute: 'roles',
        }),

        pagination({
            container: '#pagination',
        })
    ]);

    advancedTvShowSearch.start();
}

$('.js-menu').slicknav({
    allowParentLinks: true,
});
$('.js-slick-home').slick({
    speed: 600,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'linear',
    pauseOnHover: true,
    dots: true,
    arrows: false,
});
$('.js-slick-category').slick({
    speed: 600,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: true,
    spaceBetween: 10,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
});
$('.character-list').each(function () {
    const $trigger = $(this).find('.button');
    const $content = $(this).find('.collapse');

    $trigger.on('click', function () {
        $trigger.hide();
        $content.slideToggle();
    });
});
Fancybox.bind('[data-fancybox]', {
    l10n: fr,
    Toolbar: {
        display: {
            right: ['close'],
        },
    },
});
$('.dropdown-trigger').each(function () {
    const $trigger = $(this);
    const $dropdown = $trigger.next('.dropdown-content');

    $trigger.on('click', function () {
        $trigger.toggleClass('is-open');
        $dropdown.slideToggle();
    });
});
